import React from 'react'
import I18n from '../utils/I18n'
import HeroCard from './HeroCard'


const OverviewBanner = ({ currentSlug, lang }) => {
  const i18n = new I18n({lang})
  let cardItemType = 'overview'
  let cardItemSlug = 'associateoverview'

  if(currentSlug === 'associateoverview') {
	cardItemType = 'overview'
	cardItemSlug = 'administratoroverview'
  }

  return (
    <div className="cert-overview-hero">
		{/*
		<tds-alert dismissible="false">
			<span style={{ fontSize: '16px', fontWeight: '400' }}>
				<p dangerouslySetInnerHTML={{ __html: i18n.text('overviewBanner_alert') }}></p>			
			</span>
		</tds-alert>
		*/}

    	<div>
    		<div className="cert-overview-hero_content slds-container--center">
				<div className="slds-grid slds-wrap slds-grid_align-spread">
    				<div className="slds-large-size--1-of-2 slds-size--1-of-1 cert-overview-hero_col-1">
    					<div>
    						<div className="cert-overview-hero_title">
    							{i18n.text('overviewBanner_title')}
    						</div>

    						<div className="cert-overview-hero_description">
                  				{i18n.text('overviewBanner_description')}
                			</div>
    					</div>
    				</div>

					<HeroCard type={cardItemType} cardItemSlug={cardItemSlug} currentSlug={currentSlug} lang={lang}/>
    			</div>
    		</div>
    	</div>
    </div>
  )
}

export default OverviewBanner
