import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../utils/I18n';

const VerifyCredential = ({lang}) => {
  const i18n = new I18n({ lang });
  return (
    <div className="cert-verify-bg ">
      <div className="slds-container--x-large slds-container--center slds-p-horizontal_medium">
        <div className="slds-grid slds-wrap slds-grid_vertical-stretch">
          <div className="slds-size--1-of-1 slds-large-size--1-of-2">
            <div className="cert-verify_title">
              <h1>{i18n.text("footer_title")}</h1>
            </div>

            <div className="slds-m-top--large cert-verify_subtitle">
              {i18n.text("footer_description")}
            </div>
            <a
              className="slds-button slds-button_brand tds-button_x-large tds-button_brand slds-m-top--x-large slds-m-bottom--large"
              href={`/${lang}/credentials/verification`}>
                {i18n.text("footer_ctaText")}
            </a>
          </div>

          <div className="slds-size--1-of-1 slds-large-size--1-of-2 slds-text-align--center slds-align-bottom">
            {/* <img src="https://developer.salesforce.com/resources2/certification-site/images/roles/role-bottom-img.png" role="presentation" alt=""/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyCredential;
