import React from 'react'
import PropTypes from 'prop-types'
//import Hyphenated from 'react-hyphen'
import I18n from '../utils/I18n'
import Translation from '../utils/Translation'
import overviews from '../../data/overviews.json'

const RoleMenu = ({currentSlug, lang}) => {
  const i18n = new I18n({ lang })
  const activeCssClass = 'roleMenu_active'
  const roleImgPath = 'https://developer.salesforce.com/resources2/certification-site/images/roles'

  const filteredOverviews = overviews.filter(it => it.menuOrder >=0)

  const itemsEN = Translation.getItems(filteredOverviews, null, 'slug', 'en').sortAsc('menuOrder')
  const items = Translation.getItems(filteredOverviews, null, 'slug', lang).sortAsc('menuOrder')
  
  const images = {}

  // put the EN images
  itemsEN.forEach(itemEn => {
    images[itemEn.slug] = itemEn.menuImg
  })

  return (
    <div className="slds-container--center slds-m-bottom--x-large slds-is--relative roleMenu-container">
      <div className="roleMenu-menu">
        <div className="slds-container--small slds-container--center slds-text-align--center roleMenu_title">
          <h3>{i18n.text('overviewMenu_title')}</h3>
        </div>

        <div className="slds-container--center slds-text-align--center slds-p-top--small slds-p-bottom--medium roleMenu_description">
          {i18n.text('overviewMenu_subtitle')}
        </div>

        <div className="slds-grid slds-wrap slds-grid--align-center">
          {items.map((item, index) => (
            <div key={`role${index}`} className="slds-p-horizontal--small slds-p-top--small">
              <div className={`roleMenu-item ${item.slug == currentSlug ? activeCssClass : ''}`}>
                <a className="roleMenu_no-underline" href={`/${lang}/credentials/${item.slug}`}>
                  <img
                    alt={item.menuTitle}
                    className="roleMenu-item_img"
                    src={`${roleImgPath}/${images[item.slug]}`} 
                  />
                  <div className="roleMenu-item_text" dangerouslySetInnerHTML={{__html: item.menuTitle}} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

RoleMenu.propTypes = {
  currentSlug: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
}

export default RoleMenu
