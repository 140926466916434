import React from 'react'
//import PropTypes from 'prop-types'
import Hyphenated from 'react-hyphen'
import Truncate from 'react-truncate'
import I18n from '../utils/I18n'
import Translation from '../utils/Translation'
import credentials from "../../data/certifications.json"
import superbadges from "../../data/superbadges.json"
import overviews from "../../data/overviews.json"

const HeroCard = ({type, cardItemSlug, style, currentSlug, lang}) => {
  console.log('currentSlug', currentSlug)
  const i18n = new I18n({ lang })
  const toSlug = (s) => {return s.replace(/_/g, '')}
  const styleClass = style ? `credentials-card_${style}` : '';
  const displayExcludingSlugs = []

  let card = null

  if (type === 'certification') {
    const data = credentials
    const itemEN = Translation.getItems(data, [cardItemSlug], 'slug', 'en')[0] || {}
    const itemTranslated = Translation.getItems(data, [cardItemSlug], 'slug', lang)[0] || itemEN

    if(itemEN && itemTranslated) {
      card = {
        type: type,
        typeText: i18n.text('credentialCard_certification'),
        cta_url: itemEN.cardCtaLink ? `/${lang}/${itemEN.cardCtaLink}` : i18n.text('credentialCard_scheduleNowLink'),
        cta_txt: itemTranslated.cardCtaLabel ? itemTranslated.cardCtaLabel : i18n.text('credentialCard_scheduleNow'),
        cta_txt_now: itemTranslated.cardCtaLabel ? itemTranslated.cardCtaLabel : i18n.text('credentialCard_Coming Soon'),
        itemTitle: itemTranslated.title,
        itemUrl: `/${lang}/credentials/${itemEN.slug}`,
        imageUrl: itemEN.cardImg,
        descriptionShort: itemTranslated.cardTextShort || itemTranslated.cardText
      }
    }
  
  } else if (type === 'overview') {
    const data = overviews
    const itemEN = Translation.getItems(data, [cardItemSlug], 'slug', 'en')[0] || {}
    const itemTranslated = Translation.getItems(data, [cardItemSlug], 'slug', lang)[0] || itemEN

    if(itemEN && itemTranslated) {
      card = {
        type: type,
        typeText: null,
        cta_url: `/${lang}/credentials/${itemEN.slug}`,
        cta_txt: i18n.text('credentialCard_learnMore'),
        cta_txt_now: null,
        itemTitle: itemTranslated.heroCardTitle || itemTranslated.title || itemEN.heroCardTitle || itemEN.title,
        itemUrl: `/${lang}/credentials/${itemEN.slug}`,
        imageUrl: itemEN.heroCardImage,
        descriptionShort: itemTranslated.heroCardDescription || itemTranslated.cardText || itemEN.heroCardDescription || itemEN.cardText
      }
    }
  
  } else if (type === 'superbadge') {
    const data = superbadges
    const itemEN = Translation.getItems(data, [cardItemSlug], 'slug', 'en')[0] || {}
    const itemTranslated = Translation.getItems(data, [cardItemSlug], 'slug', lang)[0] || itemEN

    if(itemEN && itemTranslated) {
      card = {
        type: type,
        typeText: i18n.text('type_superbadge'),
        cta_url: `${itemEN.cta_url}`,
        cta_txt: i18n.text('credentialCard_moreDetails'),
        itemTitle: itemTranslated.title,
        itemUrl: itemEN.url,
        imageUrl: itemEN.img,
        descriptionShort: itemTranslated.cardTextShort,
        modules: itemEN.modules,
        txt_line2: itemTranslated.txt_line2
      }
    }
  
  } else if (type === 'superset') {
    const data = superbadges
    const itemEN = Translation.getItems(data, [cardItemSlug], 'slug', 'en')[0] || {}
    const itemTranslated = Translation.getItems(data, [cardItemSlug], 'slug', lang)[0] || itemEN

    if(itemEN && itemTranslated) {
      card = {
        type: type,
        typeText: i18n.text('type_superset'),
        cta_url: `${itemEN.cta_url}`,
        cta_txt: i18n.text('credentialCard_moreDetails'),
        itemTitle: itemTranslated.title,
        itemUrl: itemEN.url,
        imageUrl: itemEN.img,
        descriptionShort: itemTranslated.cardTextShort,
        modules: itemEN.modules,
        txt_line2: itemTranslated.txt_line2
      }
    }
  }

  return (
    <>
      {card && !displayExcludingSlugs.includes(currentSlug) && (
        <div className="slds-large-size--1-of-2 slds-size--1-of-1 cert-overview-hero_col-2">
          <div className="cert-overview-hero_card">
            <img className="cert-overview-hero_card-butterfly" src="https://developer.salesforce.com/resources2/certification-site/images/hero/butterfly.png" alt="" role="presentation"/>
            
            <div className="slds-container--center slds-container--x-large">
              <div>
                <div className={`credentialBanner-card ${styleClass}`}>
                  <img className="credentialBanner-card_image" alt="Logo for Salesforce Certified Professional" src={card.imageUrl}></img>
                  
                  <div className="F(r)">
                    {(card.type === 'certification' || card.type === 'overview') && (
                      <div className="slds-text-align--right">
                        <a className="cert-btn--grey slds-m-top--x-small CTA" target="_blank" href={card.cta_url} > 
                          <Hyphenated>{card.cta_txt}</Hyphenated>
                        </a>
                      </div>
                    )}
        
                    {card.type === 'superbadge' && (
                      <div className="slds-text-align--right credentials-card-superbadge_gray">
                        {card.modules != "" && (
                          <>
                            {card.modules == 1 && (
                              i18n.text('credentialCard_OneModule')
                            )}
                            {card.modules > 1 && (
                              i18n.text('credentialCard_MultipleModules', card.modules)
                            )}
                          </>
                        )}
                        {card.modules == "" && (<>{card.txt_line2}</>)}
                      </div>
                    )}
        
                  </div>
                  <div className="credentialBanner-card_type">{card.typeText}</div>
                  
                  <div className="credentialBanner-card_title">
                    <a href={card.itemUrl} dangerouslySetInnerHTML={{ __html: card.itemTitle }}></a>
                  </div>
        
                  <div className="credentialBanner-card_description">
                    <Hyphenated>
                      <Truncate lines={3} ellipsis={<span>...</span>}>
                        {card.descriptionShort}
                      </Truncate>
                    </Hyphenated>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
)
}

export default HeroCard
